<template>
  <div>
    <MainStepper e1="3" />
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-1">
          <v-row>
            <v-col cols="4">
              <span class="text-h6 primary--text">EVALUATED TENDERS</span>
            </v-col>
            <v-col cols="8">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToEvaluationReports"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-file-chart</v-icon>
                  </v-btn>
                </template>
                <span>Evaluation reports</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToSupplierSelection"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                  </v-btn>
                </template>
                <span>Back to supplier selection</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.closing_date`]="{ item }">
        {{ formatDateDefault(item.closing_date) }}
      </template>
      <template v-slot:[`item.documents`]="{ item }">
        {{ item.name }}
        <a
          v-for="(item, i) in item.documents"
          :key="i"
          link
          v-bind:href="docURI"
          >({{ i + 1 }}) {{ item.name }} ,
        </a>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <p v-if="item.status.slug == 'closed'" class="my-auto">
          <a
            @click.stop="tenderReportPreview(item)"
            class="success--text text-decoration-underline"
          >
            Evaluation Completed
          </a>
        </p>
        <p v-else class="primary--text my-auto">Evaluations in Progress</p>
      </template>
      <template v-slot:[`item.company`]="{ item }">
        {{ getCompanyName(item.company) }}
      </template>
      <template v-slot:[`item.tender_no`]="{ item }">
        <a
          @click.stop="tenderPreview(item)"
          class="primary--text text--darken-1 text-decoration-underline"
          >{{ item.tender_no }}</a
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <v-btn
            color="grey"
            class="mx-1"
            fab
            x-small
            dark
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="mx-1"
            fab
            x-small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TenderService from "../../services/tender.service";
import moment from "moment";
import User from "../../models/user";
import MainStepper from "../../components/MainStepper.vue";

export default {
  components: {
    MainStepper,
  },
  data: (vm) => ({
    name: "MarketPlace",
    items: [],
    requiredDocuments: [],
    technicalDocuments: [],
    financialDocuments: [],
    editedIndex: -1,

    startDateWatcher: new Date().toISOString().substr(0, 10),
    menu_start_date: false,
    clarificationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_clarification_date: false,
    evaluationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_evaluation_date: false,
    approvalDateWatcher: new Date().toISOString().substr(0, 10),
    menu_approval_date: false,

    editedItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      start_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      status: "",
      selectedRequiredDocuments: [],
      selectedTechnicalDocuments: [],
      selectedFinancialDocuments: [],
    },
    defaultItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      start_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      status: "",
      loading: false,
      selectedRequiredDocuments: [],
      selectedTechnicalDocuments: [],
      selectedFinancialDocuments: [],
    },

    tender_steps: [
      {
        id: 1,
        name: "One step",
      },
      {
        id: 2,
        name: "Two step",
      },
      {
        id: 3,
        name: "Three step",
      },
    ],
    tender_status: [
      {
        id: 1,
        name: "Draft",
      },
      {
        id: 2,
        name: "Open",
      },
      {
        id: 3,
        name: "Closed",
      },
    ],
    page: "Market",
    selected: [0],
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: [],
    user: new User("", ""),
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],
    valid: true,
    requiredRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],

    headers: [
      {
        text: "Tender No",
        align: "start",
        filterable: true,
        value: "tender_no",
      },
      {
        text: "Tender Details",
        value: "title",
      },
      {
        text: "Category",
        value: "category.title",
      },
      {
        text: "Closing Date",
        value: "closing_date",
      },
      {
        text: "Status (Click to view report)",
        value: "status",
      },
      //   {
      //     text: "Actions",
      //     align: "start",
      //     value: "actions",
      //     sortable: false,
      //   },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Tender" : "Edit Tender";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedStartDate() {
      return moment(this.editedItem.start_date).format("DD-MM-YYYY");
    },
    computedClarificationDate() {
      return moment(this.editedItem.clarification_end_date).format(
        "DD-MM-YYYY"
      );
    },
    computedEvaluationDate() {
      return moment(this.editedItem.evaluation_end_date).format("DD-MM-YYYY");
    },
    computedApprovalDate() {
      return moment(this.editedItem.tender_approval_date).format("DD-MM-YYYY");
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.selectedRequiredDocuments.length ===
        this.requiredDocuments.length
      );
    },
    likesSomeRequiredDocuments() {
      return (
        this.editedItem.selectedRequiredDocuments.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomeRequiredDocuments) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // technical docs
    likesAllTechnicalDocument() {
      return (
        this.editedItem.selectedTechnicalDocuments.length ===
        this.technicalDocuments.length
      );
    },
    likesSomeTechnicalDocuments() {
      return (
        this.editedItem.selectedTechnicalDocuments.length > 0 &&
        !this.likesAllTechnicalDocument
      );
    },
    iconTechnical() {
      if (this.likesAllTechnicalDocument) return "mdi-close-box";
      if (this.likesSomeTechnicalDocuments) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // financial docs
    likesAllFinancialDocument() {
      return (
        this.editedItem.selectedFinancialDocuments.length ===
        this.technicalDocuments.length
      );
    },
    likesSomeFinancialDocuments() {
      return (
        this.editedItem.selectedFinancialDocuments.length > 0 &&
        !this.likesAllFinancialDocument
      );
    },
    iconFinancial() {
      if (this.likesAllFinancialDocument) return "mdi-close-box";
      if (this.likesSomeFinancialDocuments) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    startDateWatcher() {
      this.editedItem.start_date = this.formatDate(this.startDateWatcher);
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },

  created() {
    this.getAllTenders();
    this.getCategories();
    this.getAllRequiredDocuments();
    this.getAllTechnicalDocuments();
    this.getAllFinancialDocuments();
  },

  methods: {

    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    goToEvaluationReports(){
      this.$router.push("/report");
    },
    tenderReportPreview(item) {
      this.$store.dispatch("tender/setSelected", item);
      this.$router.push("/evaluation-report");
    },
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.selectedRequiredDocuments = [];
        } else {
          this.editedItem.selectedRequiredDocuments =
            this.requiredDocuments.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.requiredDocuments = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // technical docs
    toggleTechnical() {
      this.$nextTick(() => {
        if (this.likesAllTechnicalDocument) {
          this.editedItem.selectedTechnicalDocuments = [];
        } else {
          this.editedItem.selectedTechnicalDocuments =
            this.technicalDocuments.slice();
        }
      });
    },
    getAllTechnicalDocuments() {
      return TenderService.getAllTechnicalDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.technicalDocuments = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // financial docs
    toggleFinancial() {
      this.$nextTick(() => {
        if (this.likesAllFinancialDocument) {
          this.editedItem.selectedFinancialDocuments = [];
        } else {
          this.editedItem.selectedFinancialDocuments =
            this.financialDocuments.slice();
        }
      });
    },
    getAllFinancialDocuments() {
      return TenderService.getAllFinancialDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.financialDocuments = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDateDefault(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY h:mm:ss a");
    },

    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
      localStorage.setItem("tenderItem", JSON.stringify(item));
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getAllTenders() {
      this.loading = true;
      return TenderService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        TenderService.deleteUser(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          console.log(this.editedItem);
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.updateTender(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          console.log(this.editedItem);
          TenderService.storeTender(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Tender created successfully"
                );
                this.getAllTenders();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>