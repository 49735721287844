var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainStepper',{attrs:{"e1":"3"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-1",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h6 primary--text"},[_vm._v("EVALUATED TENDERS")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize ml-1 float-right",attrs:{"color":"primary","dark":"","loading":_vm.loading,"elevation":"0","fab":"","x-small":""},on:{"click":_vm.goToEvaluationReports}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-chart")])],1)]}}])},[_c('span',[_vm._v("Evaluation reports")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize ml-1 float-right",attrs:{"color":"primary","dark":"","loading":_vm.loading,"elevation":"0","fab":"","x-small":""},on:{"click":_vm.goToSupplierSelection}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}])},[_c('span',[_vm._v("Back to supplier selection")])])],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-divider')]},proxy:true},{key:"item.closing_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateDefault(item.closing_date))+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_vm._l((item.documents),function(item,i){return _c('a',{key:i,attrs:{"link":"","href":_vm.docURI}},[_vm._v("("+_vm._s(i + 1)+") "+_vm._s(item.name)+" , ")])})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.slug == 'closed')?_c('p',{staticClass:"my-auto"},[_c('a',{staticClass:"success--text text-decoration-underline",on:{"click":function($event){$event.stopPropagation();return _vm.tenderReportPreview(item)}}},[_vm._v(" Evaluation Completed ")])]):_c('p',{staticClass:"primary--text my-auto"},[_vm._v("Evaluations in Progress")])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyName(item.company))+" ")]}},{key:"item.tender_no",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text text--darken-1 text-decoration-underline",on:{"click":function($event){$event.stopPropagation();return _vm.tenderPreview(item)}}},[_vm._v(_vm._s(item.tender_no))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"grey","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }